import React from "react";
import Footer from "src/components/Footer_it";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/it_IT/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/it/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/it/prodotti/" target="_self">
                      Prodotti
                    </a>
                    <ul>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/moisturising/">Moisturising</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/instantglow/">Instant Glow</a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/it/our-heritage/" target="_self">
                      Storia
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/it/know_the_sun/" target="_self">
                      Conosci il sole
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/it/Ma-Dove-Sei-Stata/" target="_self">
                      Ma Dove Sei Stata 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Paesi{" "}
                    </a>
                    <ul className="subMenu">
                      
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/hydro-infusion/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/Banner-Hidroinfusion-ProductPage.jpg"
                    data-mobile="/assets/images/Banners_Mobile_hydroinfusion (2).png"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> HYDRO INFUSION
                    </h1>
                    <div className="products-page__intro__description">
                      <p>
                        <strong>
                          Assorbimento rapido e sensazione di freschezza
                        </strong>
                        <br />
                        Scopri l’immediata sensazione di freschezza della linea
                        di prodotti PIZ BUIN HYDRO INFUSION®. Proteggendoti dai
                        raggi UVA e idratando al tempo stesso la tua pelle, i
                        prodotti HYDRO INFUSION® si prendono cura delle aree più
                        danneggiate dai raggi solari, regalandoti un aspetto
                        sano e una piacevole sensazione di benessere. Resistenti
                        all’acqua e al sudore.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="sun-gel-cream-face" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/hydroinfusion-50.png" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>
                        Piz Buin Hydro Infusion Sun Gel Cream Face SPF 50 High
                        Protection{" "}
                      </h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFICI</h4>
                        <p>
                          - Rinfresca immediatamente, protegge e idrata la pelle
                          stressata dal sole, lasciandola morbida e dall’aspetto
                          sano.
                        </p>
                        <p>
                          - Tripla resistenza: Contiene filtri UVA/UVB avanzati
                          per una protezione immediata ed efficace.
                        </p>
                        <p>
                          - Aiuta a proteggere la pelle da agenti aggressivi
                          come cloro, mare e acqua salata.
                        </p>
                        <p>- Resistente ad acqua e sudore.</p>
                        <p>
                          - Protegge la pelle dai raggi UVA lunghi, dai danni e
                          dall’invecchiamento precoce causati dall’esposizione
                          al sole.
                        </p>
                        <p>
                          - Assorbimento rapido, nessun residuo bianco, non
                          appiccica e non unge.
                        </p>
                        <p>
                          - Lascia la pelle levigata. Ideale per l’applicazione
                          quotidiana ed utilizzabile con il trucco.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>Disponibile in</h4>
                        <span className="factor factor30" />
                        {/* <span className="factor factor50" /> */}
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>APPLICAZIONE</h4>
                        <p>
                          Applicare abbondantemente e uniformemente prima
                          dell’esposizione al sole. Diminuendo la quantità il
                          livello di protezione si riduce sensibilmente.
                          Riapplicare frequentemente, soprattutto dopo aver
                          sudato, nuotato o essersi asciugati con l’asciugamano.
                          Evitare l’esposizione al sole nelle ore centrali del
                          giorno ed evitare di rimanere troppo tempo al sole
                          anche con la protezione. Tenere neonati e bambini al
                          riparo dalla luce diretta del sole.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTI<span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                <div className="textBlock bottom">
                                  <p>
                                    Aqua, Homosalate, Octocrylene, Glycerin,
                                    Butyl Methoxydibenzoylmethane,
                                    BisEthylhexyloxyphenol Methoxyphenyl
                                    Triazine, Styrene/Acrylates Copolymer,
                                    Alcohol Denat., Caprylyl Methicone,
                                    Diisopropyl Adipate, Silica, Dicaprylyl
                                    Carbonate, Glycine Soja Oil, Dimethicone,
                                    Pentylene Glycol, Linseed Acid, Hydrogenated
                                    Palm Glycerides, Glyceryl Stearate, Menthyl
                                    Lactate, Acrylates/Dimethicone Copolymer,
                                    Acrylates Copolymer, Trideceth-6, PEG-8
                                    Laurate, Potassium Cetyl Phosphate, Sodium
                                    Dodecylbenzenesulfonate, Acrylates/C10-30
                                    AlkylAcrylateCrosspolymer,Polyurethane-62,
                                    Disodium EDTA, Sodium Hydroxide,
                                    Hydroxyacetophenone, Tocopherol, Tocopheryl
                                    Acetate, Phenoxyethanol, Parfum.[PR-018945]
                                  </p>
                                  <div> </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="sun-gel-cream" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-hydro-infusion-sun-gel-cream-30spf-150ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>
                        Piz Buin Hydro Infusion Sun Gel Cream SPF 30 High
                        Protection{" "}
                      </h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFICI</h4>
                        <p>
                          - Protegge e idrata la pelle stressata dal sole
                          lasciandola morbida e dall’aspetto sano.
                        </p>
                        <p>- Rinfresca immediatamente.</p>
                        <p>
                          - Tripla resistenza: Contiene filtri UVA/UVB avanzati
                          per una protezione immediata ed efficace.
                        </p>
                        <p>
                          - Aiuta a proteggere la pelle da agenti aggressivi
                          come cloro, mare e acqua salata.
                        </p>
                        <p>- Resistente ad acqua e sudore.</p>
                        <p>- Protegge dai raggi UVA lunghi.</p>
                        <p>
                          - Protegge la pelle dai danni e dall’invecchiamento
                          precoce causati dall’esposizione al sole.
                        </p>
                        <p>
                          - Ad assorbimento rapido, non lascia residui bianchi.
                        </p>
                        <p>- Non appiccica e non unge.</p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>Disponibile in</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                        <span className="factor factor500" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>APPLICAZIONE</h4>
                        <p>
                          Applicare abbondantemente e uniformemente prima
                          dell’esposizione al sole. Diminuendo la quantità il
                          livello di protezione si riduce sensibilmente.
                          Riapplicare frequentemente, soprattutto dopo aver
                          sudato, nuotato o essersi asciugati con l’asciugamano.
                          Evitare l’esposizione al sole nelle ore centrali del
                          giorno ed evitare di rimanere troppo tempo al sole
                          anche con la protezione. Tenere neonati e bambini al
                          riparo dalla luce diretta del sole.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTI<span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                <div className="textBlock bottom">
                                  <p>
                                    Aqua, Homosalate, Octocrylene, Glycerin,
                                    Butyl Methoxydibenzoylmethane,
                                    BisEthylhexyloxyphenol Methoxyphenyl
                                    Triazine, Styrene/Acrylates Copolymer,
                                    Alcohol Denat., Caprylyl Methicone,
                                    Diisopropyl Adipate, Silica, Dicaprylyl
                                    Carbonate, Glycine Soja Oil, Dimethicone,
                                    Pentylene Glycol, Linseed Acid, Hydrogenated
                                    Palm Glycerides, Glyceryl Stearate, Menthyl
                                    Lactate, Acrylates/Dimethicone Copolymer,
                                    Acrylates Copolymer, Trideceth-6, PEG-8
                                    Laurate, Potassium Cetyl Phosphate, Sodium
                                    Dodecylbenzenesulfonate, Acrylates/C10-30
                                    AlkylAcrylateCrosspolymer,Polyurethane-62,
                                    Disodium EDTA, Sodium Hydroxide,
                                    Hydroxyacetophenone, Tocopherol, Tocopheryl
                                    Acetate, Phenoxyethanol, Parfum.[PR-018945]
                                  </p>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected first"
                      href="/it/prodotti/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a href="/it/prodotti/active-protect/" target="_self">
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a href="/it/prodotti/moisturising/" target="_self">
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a href="/it/prodotti/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a href="/it/prodotti/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/aftersun/" target="_self">
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/gallery/" target="_self">
                      Gallery
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinItalia?fref=ts"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuin_italia/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// Conversion Name: LP/HP Piz buin\n// INSTRUCTIONS \n// The Conversion Tags should be placed at the top of the <BODY> section of the HTML page. \n// In case you want to ensure that the full page loads as a prerequisite for a conversion \n// being recorded, place the tag at the bottom of the page. Note, however, that this may \n// skew the data in the case of slow-loading pages and in general not recommended. \n//\n// NOTE: It is possible to test if the tags are working correctly before campaign launch \n// as follows:  Browse to http://bs.serving-sys.com/Serving/adServer.bs?cn=at, which is \n// a page that lets you set your local machine to 'testing' mode.  In this mode, when \n// visiting a page that includes an conversion tag, a new window will open, showing you \n// the data sent by the conversion tag to the MediaMind servers. \n// \n// END of instructions (These instruction lines can be deleted from the actual HTML)\nvar ebRand = Math.random()+'';\nebRand = ebRand * 1000000;\ndocument.write('<scr'+'ipt src=\"HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;rnd=' + ebRand + '\"></scr' + 'ipt>');\n"
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <img width="1" height="1" style="border:0" src="HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;ns=1">\n      '
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Leggi di piu´');\n} else {\nbox.slideDown();\n$(this).text('Leggi meno');\n}\n});\n});\n"
          }}
        />
                <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
